<template>
  <action-dispatcher action="content/getModules">
    <form class="view-admin-new-exam" @submit.prevent="submit">
      <form-group
        id="name"
        select
        v-model="form.module"
        label="Á qual prova esta questão pertence?"
        type="select"
        placeholder="Escolha o típo da questão"
        :errors="errors.type"
        :options="moduleOptions"
        class="mb-4"
      />

      <form-group
        id="name"
        v-model="form.title"
        label="Título da questão"
        type="text"
        placeholder="Digite título da questão"
        :errors="errors.title"
        class="mb-4"
      />

      <form-group
        id="name"
        select
        v-model="form.type"
        label="Típo da questão"
        type="select"
        placeholder="Escolha o típo da questão"
        :errors="errors.type"
        :options="typeOptions"
        class="mb-4"
      />

      <template v-if="form.type == 'alternative'">
        <h5 class="mb-4">Alternativas:</h5>
        <div class="alternatives-wrapper my-3">
          <div class="d-flex">
            <small class="font-weight-bold mr-4">Resposta correta?</small>
            <div class="flex-fill"></div>
            <small class="font-weight-bold">Ação</small>
          </div>
          <div
            v-for="(alt, i) in form.alternatives"
            :key="`alt-${i}`"
            class="d-flex align-items-center"
          >
            <b-form-radio
              :id="alt.id"
              class="mr-5"
              :value="alt.id"
              v-model="answer"
            ></b-form-radio>
            <form-group
              v-model="alt.text"
              id="text"
              type="text"
              placeholder="Ex: Sim"
              textarea
              rows="1"
              class="flex-fill"
            />
            <button
              type="button"
              class="btn text-danger"
              @click="removeAlternative(alt.id)"
            >
              <i class="icon-trash"></i>
            </button>
          </div>
        </div>

        <button
          type="button"
          class="btn btn-outline-primary btn-block"
          @click="addAlternative"
        >
          Adicionar alternativa
        </button>
      </template>

      <div
        class="row align-items-center justify-content-between mt-5 pt-4 pt-lg-5"
      >
        <div class="col-lg-4 order-2 order-lg-1">
          <button
            type="button"
            class="btn btn-outline-secondary btn-block"
            @click="$router.go(-1)"
          >
            Voltar
          </button>
        </div>
        <div class="col-lg-4 order-1 order-lg-2">
          <button type="submit" class="btn btn-primary btn-block">
            <loading :show="submitLoading">
              Salvar
            </loading>
          </button>
        </div>
      </div>
    </form>
  </action-dispatcher>
</template>
<script>
import { formFields } from "@/functions/helpers";
import { mapState } from "vuex";
import get from "lodash/get";
export default {
  mounted() {
    if (this.$route.query.module) {
      this.$store
        .dispatch("questions/get", this.$route.query.module)
        .then(data => {
          this.module_questions = data;
        });
    }

    if (this.$route.params.id) {
      this.pageLoading = true;
      this.$store
        .dispatch("questions/getQuestion", this.$route.params.id)
        .then(data => {
          this.form = data;
          this.answer = data.alternatives.find(a => a.is_right).id;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }
  },
  computed: {
    ...mapState("content", ["modules"]),
    moduleOptions() {
      return this.modules.map(({ id, name }) => ({ id, name }));
    },
    typeOptions() {
      return [
        {
          id: "opened",
          name: "Discursiva"
        },
        {
          id: "alternative",
          name: "Objetiva"
        }
      ];
    }
  },
  data() {
    return {
      ...formFields(["title", "type", "module", "alternatives"], {
        module: get(this.$route.query, "module", ""),
        alternatives: [
          {
            id: `${new Date().getTime()}`,
            text: "",
            is_right: false,
            sort: 0
          }
        ]
      }),
      module_questions: [],
      pageLoading: false,
      submitLoading: false,
      answer: false
    };
  },
  watch: {
    "form.module"(id) {
      this.$store.dispatch("questions/get", id).then(data => {
        this.module_questions = data;
      });
    },
    answer(id) {
      this.form.alternatives = this.form.alternatives.map(alt => ({
        ...alt,
        is_right: alt.id == id
      }));
    }
  },
  methods: {
    addAlternative() {
      this.form.alternatives = [
        ...this.form.alternatives,
        {
          id: `${new Date().getTime()}`,
          text: "",
          is_right: false,
          sort: this.form.alternatives.length
        }
      ];
    },
    removeAlternative(id) {
      this.form.alternatives = this.form.alternatives.filter(a => a.id !== id);
    },
    submit() {
      if (this.submitLoading) return;

      if (!this.form?.title || !this.form?.title.length) {
        this.$message.warning("Por favor, digite o título da pergunta");
        return;
      }

      if (this.form.type == "alternative") {
        if (this.form.alternatives.length < 2) {
          this.$message.warning(
            "Por favor, Cadastre pelo menos 2 alternativas"
          );
          return;
        }

        const existMarkedAnswer = this.form.alternatives.reduce(
          (acc, curr) => acc || curr.is_right,
          false
        );

        if (!existMarkedAnswer) {
          this.$message.warning("Por favor, indique a resposta correta");
          return;
        }

        const existMissingTextAnswer = this.form.alternatives.reduce(
          (acc, curr) => acc || curr.text.length == 0,
          false
        );

        if (existMissingTextAnswer) {
          this.$message.warning(
            "Por favor, ainda existe uma alternativa sem texto"
          );
          return;
        }
      }

      const action = this.$route.params.id ? "questions/put" : "questions/post";

      const payload = { ...this.form, created_at: new Date().toISOString() };

      let msg = "Pergunta adicionada com sucesso!";

      if (this.$route.params.id) {
        msg = "Pergunta editada com sucesso!";
        payload.id = this.$route.params.id;
      } else {
        payload.sort = this.module_questions.length;
      }

      this.submitLoading = true;

      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.$message.success(msg);
          this.$router.push({
            name: "admin-module-questions",
            params: { id: this.form.module }
          });
        })
        .catch(e => {
          this.$message.error("Não foi possível cadastrar a nova pergunta");
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-admin-new-exam {
}
</style>
